// extracted by mini-css-extract-plugin
export var carousel = "product-images-module--carousel--BVw9g";
export var carouselImage = "product-images-module--carouselImage--5FCj1";
export var carouselInner = "product-images-module--carouselInner--5ovIO";
export var carouselNext = "product-images-module--carouselNext--JrliT";
export var carouselPrev = "product-images-module--carouselPrev--oaM3F";
export var carouselSlide = "product-images-module--carouselSlide--tvIHb";
export var imagesModal = "product-images-module--imagesModal--APP-y";
export var modalClose = "product-images-module--modalClose--XBdpt typography--crossButton--5BLK4";
export var prodMainImage = "product-images-module--prodMainImage--fQfoF";
export var thumbImage = "product-images-module--thumbImage--q9itT";
export var thumbImages = "product-images-module--thumbImages--oPao+";